import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { regexPatterns } from '../constants';
import { Zip } from '../models/app.models';
import {
  AddInviteeToListFormGroup,
  AddressFormData,
  AddressFormGroup,
  ClientFilterFormGroup,
  EditInviteeFormData,
  EditInviteeFormGroup,
  EditProfileFormData,
  EditProfileFormGroup,
  EmployeeFilterFormGroup,
  EventFilterFormData,
  EventFilterFormGroup,
  LabelFormData,
  LabelFormGroup,
  ListFilterFormGroup,
  ListInviteesFilterFormGroup,
  ListLinkFormData,
  ListLinkFormGroup,
  ListStepOneFormGroup,
  ListStepTwoFormData,
  ListStepTwoFormGroup,
  LoginFormGroup,
  LoungeFormData,
  LoungeFormGroup,
  LoungesFilterFormGroup,
  PasswordChangeFormGroup,
  PersonalInformationFormData,
  PersonalInformationFormGroup,
  ReservationFormData,
  ReservationFormGroup,
  ReservationRequestFormData,
  ReservationRequestFormGroup,
  ReservationsFilterFormGroup,
} from '../models/form.models';
import { urlValidator } from 'src/app/features/club/club-edit/url.validator';
import {
  passwordMatchValidator,
  requiredIfEnabledValidator,
  validateChosenValue,
  validateDate,
} from '../helpers/validators';
import { addDays, format } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  private formGroups = {
    loginFormGroup: new FormGroup<LoginFormGroup>({
      email: new FormControl<string>('', [
        Validators.required,
        Validators.pattern(regexPatterns.mailRegexApi),
      ]),
      password: new FormControl<string>('', [Validators.required]),
    }),
    eventFilterFormGroup: new FormGroup<EventFilterFormGroup>({
      filterContains: new FormControl<string>('', {}),
      dateFrom: new FormControl<string>('', {}),
      dateTo: new FormControl<string>('', {}),
    }),
    personalInformationFormGroup: new FormGroup<PersonalInformationFormGroup>({
      companyName: new FormControl<string>('', {}),
      firstname: new FormControl<string>('', [Validators.required]),
      lastname: new FormControl<string>('', [Validators.required]),
      email: new FormControl<string>('', [
        Validators.required,
        Validators.pattern(regexPatterns.mailRegexApi),
      ]),
      telephone: new FormControl<string>('', [
        Validators.required,
        Validators.pattern(regexPatterns.tel),
      ]),
    }),
    labelFormGroup: new FormGroup<LabelFormGroup>({
      labelName: new FormControl<string>('', [Validators.required]),
      email: new FormControl<string>('', [
        Validators.required,
        Validators.pattern(regexPatterns.mailRegexApi),
      ]),
      socialMedia: new FormControl<string>('', [
        urlValidator(),
        Validators.maxLength(255),
      ]),
      contactPerson: new FormControl<string>('', [Validators.required]),
      customerId: new FormControl<number | null>(null, {}),
      id: new FormControl<number | null>(null, {}),
    }),
    addressFormGroup: new FormGroup<AddressFormGroup>({
      address: new FormControl<string>('', [Validators.required]),
      plz: new FormControl<Zip | null>(null, [Validators.required]),
    }),
    editProfileFormGroup: new FormGroup<EditProfileFormGroup>({
      companyName: new FormControl<string>('', {}),
      name: new FormControl<string>('', {}),
      email: new FormControl<string>('', [
        Validators.required,
        Validators.pattern(regexPatterns.mailRegexApi),
      ]),
      address: new FormControl<string>('', {}),
      plz: new FormControl<string>('', [
        Validators.required,
        Validators.pattern(regexPatterns.plz),
      ]),
      place: new FormControl<string>('', {}),
      telephone: new FormControl<string>('', [
        Validators.required,
        Validators.pattern(regexPatterns.tel),
      ]),
      nameContactPerson: new FormControl<string>('', {}),
      socialMedia: new FormControl<string>('', {}),
    }),
    passwordChangeFormGroup: new FormGroup<PasswordChangeFormGroup>(
      {
        password: new FormControl<string>('', [Validators.required]),
        passwordRepeat: new FormControl<string>('', [Validators.required]),
      },
      [passwordMatchValidator()],
    ),
    createListStepOneFormGroup: new FormGroup<ListStepOneFormGroup>({
      listType: new FormControl<number>(0, [
        Validators.required,
        validateChosenValue,
      ]),
    }),
    createListStepTwoFormGroup: new FormGroup<ListStepTwoFormGroup>({
      name: new FormControl<string>('', [Validators.required]),
      maxInvitees: new FormControl<number>(0, [
        Validators.required,
        validateChosenValue,
      ]),
      namePromoter: new FormControl<string>('', [Validators.required]),
      emailPromoter: new FormControl<string>('', [
        Validators.required,
        Validators.pattern(regexPatterns.mailRegexApi),
      ]),
      price: new FormControl<string>('', [requiredIfEnabledValidator]),
      validTillDate: new FormControl<string>(
        format(addDays(new Date(), 1), 'yyyy-MM-dd'),
        [Validators.required, validateDate],
      ),
      validTillTime: new FormControl<string>('', [
        Validators.required,
        Validators.pattern(regexPatterns.time),
      ]),
    }),
    editListStepOneFormGroup: new FormGroup<ListStepOneFormGroup>({
      listType: new FormControl<number>(0, [
        Validators.required,
        validateChosenValue,
      ]),
    }),
    editListStepTwoFormGroup: new FormGroup<ListStepTwoFormGroup>({
      name: new FormControl<string>('', [Validators.required]),
      maxInvitees: new FormControl<number>(0, [
        Validators.required,
        validateChosenValue,
      ]),
      namePromoter: new FormControl<string>('', [Validators.required]),
      emailPromoter: new FormControl<string>('', [
        Validators.required,
        Validators.pattern(regexPatterns.mailRegexApi),
      ]),
      price: new FormControl<string>('', [requiredIfEnabledValidator]),
      validTillDate: new FormControl<string>(
        format(addDays(new Date(), 1), 'yyyy-MM-dd'),
        [Validators.required, validateDate],
      ),
      validTillTime: new FormControl<string>('', [
        Validators.required,
        Validators.pattern(regexPatterns.time),
      ]),
    }),
    addInviteeToListFormGroup: new FormGroup<AddInviteeToListFormGroup>({
      firstName: new FormControl<string>('', [Validators.required]),
      name: new FormControl<string>('', [Validators.required]),
      comment: new FormControl<string>('', [Validators.maxLength(255)]),
      isCheckedIn: new FormControl<boolean>(false, [Validators.required]),
    }),
    editInviteeFormGroup: new FormGroup<EditInviteeFormGroup>({
      firstName: new FormControl<string>('', {}),
      name: new FormControl<string>('', {}),
      comment: new FormControl<string>('', [Validators.maxLength(255)]),
    }),
    listLinkFormGroup: new FormGroup<ListLinkFormGroup>({
      link: new FormControl<string>('', {}),
    }),
    listFilterFormGroup: new FormGroup<ListFilterFormGroup>({
      filterContains: new FormControl<string>('', {}),
    }),
    listInviteesFilterFormGroup: new FormGroup<ListInviteesFilterFormGroup>({
      filterContains: new FormControl<string>('', {}),
    }),
    createLoungeFormGroup: new FormGroup<LoungeFormGroup>({
      name: new FormControl<string>('', {}),
      loungeType: new FormControl<number>(0, [
        Validators.required,
        validateChosenValue,
      ]),
      numberSeats: new FormControl<number>(0, [
        Validators.required,
        validateChosenValue,
      ]),
      comment: new FormControl<string>('', [Validators.maxLength(500)]),
    }),
    editLoungeFormGroup: new FormGroup<LoungeFormGroup>({
      name: new FormControl<string>('', {}),
      loungeType: new FormControl<number>(0, [
        Validators.required,
        validateChosenValue,
      ]),
      numberSeats: new FormControl<number>(0, [
        Validators.required,
        validateChosenValue,
      ]),
      comment: new FormControl<string>('', [Validators.maxLength(500)]),
    }),
    reservationRequestFormGroup: new FormGroup<ReservationRequestFormGroup>({
      lounge: new FormControl<number>(0, [
        Validators.required,
        validateChosenValue,
      ]),
      internComment: new FormControl<string>('', [Validators.maxLength(500)]),
      message: new FormControl<string>('', [Validators.maxLength(500)]),
    }),
    reservationFormGroup: new FormGroup<ReservationFormGroup>({
      name: new FormControl<string>('', [Validators.required]),
      email: new FormControl<string>('', [
        Validators.required,
        Validators.pattern(regexPatterns.mailRegexApi),
      ]),
      telephone: new FormControl<string>('', [
        Validators.required,
        Validators.pattern(regexPatterns.tel),
      ]),
      numberPersons: new FormControl<number>(0, [
        Validators.required,
        validateChosenValue,
      ]),
      arrival: new FormControl<string>('', [
        Validators.required,
        Validators.pattern(regexPatterns.time),
      ]),
      comment: new FormControl<string>('', [Validators.maxLength(500)]),
    }),
    reservationsFilterFormGroup: new FormGroup<ReservationsFilterFormGroup>({
      filterContains: new FormControl<string>('', {}),
    }),
    loungesFilterFormGroup: new FormGroup<LoungesFilterFormGroup>({
      filterContains: new FormControl<string>('', {}),
    }),
    clientFilterFormGroup: new FormGroup<ClientFilterFormGroup>({
      filterContains: new FormControl<string>('', {}),
    }),
    employeeFilterFormGroup: new FormGroup<EmployeeFilterFormGroup>({
      filterContains: new FormControl<string>('', {}),
    }),
  };

  patchEditListFormGroup(list: ListStepTwoFormData) {
    this.getEditListStepTwoFormGroup().patchValue(list);
  }

  patchEditLoungeFormGroup(lounge: LoungeFormData) {
    this.getEditLoungeFormGroup().patchValue(lounge);
  }

  getLoginFormGroup() {
    return this.formGroups['loginFormGroup'];
  }

  getLabelFormGroup() {
    return this.formGroups['labelFormGroup'];
  }

  getEventFilterFormGroup() {
    return this.formGroups['eventFilterFormGroup'];
  }

  getListFilterFormGroup() {
    return this.formGroups['listFilterFormGroup'];
  }

  getListInviteesFilterFormGroup() {
    return this.formGroups['listInviteesFilterFormGroup'];
  }

  getClientFilterFormGroup() {
    return this.formGroups['clientFilterFormGroup'];
  }

  getEmployeeFilterFormGroup() {
    return this.formGroups['employeeFilterFormGroup'];
  }

  getReservationsFilterFormGroup() {
    return this.formGroups['reservationsFilterFormGroup'];
  }

  getLoungesFilterFormGroup() {
    return this.formGroups['loungesFilterFormGroup'];
  }

  getAddressFormGroup() {
    return this.formGroups['addressFormGroup'];
  }

  getEditProfileFormGroup() {
    return this.formGroups['editProfileFormGroup'];
  }

  getPasswordChangeFormGroup() {
    return this.formGroups['passwordChangeFormGroup'];
  }

  getPersonalInformationFormGroup() {
    return this.formGroups['personalInformationFormGroup'];
  }

  getCreateListStepOneFormGroup() {
    return this.formGroups['createListStepOneFormGroup'];
  }

  getCreateListStepTwoFormGroup() {
    return this.formGroups['createListStepTwoFormGroup'];
  }

  getEditListStepOneFormGroup() {
    return this.formGroups['editListStepOneFormGroup'];
  }

  getEditListStepTwoFormGroup() {
    return this.formGroups['editListStepTwoFormGroup'];
  }

  getAddInviteeToListFormGroup() {
    return this.formGroups['addInviteeToListFormGroup'];
  }

  getEditInviteeFormGroup() {
    return this.formGroups['editInviteeFormGroup'];
  }

  getListLinkFormGroup() {
    return this.formGroups['listLinkFormGroup'];
  }

  getCreateLoungeFormGroup() {
    return this.formGroups['createLoungeFormGroup'];
  }

  getEditLoungeFormGroup() {
    return this.formGroups['editLoungeFormGroup'];
  }

  getReservationRequestFormGroup() {
    return this.formGroups['reservationRequestFormGroup'];
  }

  getReservationFormGroup() {
    return this.formGroups['reservationFormGroup'];
  }

  patchReservationFormGroup(reservation: ReservationFormData) {
    this.formGroups.reservationFormGroup.patchValue(reservation);
  }

  patchReservationRequestFormGroup(
    reservationRequest: ReservationRequestFormData,
  ) {
    this.formGroups.reservationRequestFormGroup.patchValue(reservationRequest);
  }

  patchEditInviteeForms(editListInvitee: EditInviteeFormData) {
    this.formGroups.editInviteeFormGroup.patchValue(editListInvitee);
  }

  patchEditProfileFormGroup(editProfileForm: EditProfileFormData) {
    this.formGroups.editProfileFormGroup.patchValue(editProfileForm);
  }

  patchFilterFormGroup(filterFormGroup: EventFilterFormData) {
    this.formGroups.eventFilterFormGroup.patchValue(filterFormGroup);
  }

  patchPersonalInfoFormGroup(personalInfoData: PersonalInformationFormData) {
    this.formGroups.personalInformationFormGroup.patchValue(personalInfoData);
  }

  patchAddressFormGroup(addressFormData: AddressFormData) {
    this.formGroups.addressFormGroup.patchValue(addressFormData);
  }

  patchLabelFormGroup(labelFormGroup: LabelFormData) {
    this.formGroups.labelFormGroup.patchValue(labelFormGroup);
  }

  patchListLinkFormGroup(listLinkFormData: ListLinkFormData) {
    this.formGroups.listLinkFormGroup.patchValue(listLinkFormData);
  }
}
